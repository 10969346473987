/* ==================== About ==============*/

.about-sec {
    padding-top: 1.5rem;
}

.about .image img {
    max-width: 100%;
}

.about-sec h1 {
    font-weight: 700;
    font-family: var(--heading-font);
    font-size: 3rem;
    line-height: 1.4;
}

.about-sec .about-desc {
    color: var(--paragraph-color);
    line-height: 1.7;
    max-width: 510px;
    margin: 13px 0;
}

.about-sec .about-list {
    flex-flow: wrap;
    margin-top: 2rem;
}

.about-sec .about-list li {
    width: 50%;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.about-sec .about-list li svg {
    height: 22px;
    width: 22px;
    line-height: 22px;
    color: var(--main-color);
}

.about-sec .about-list li h6 {
    margin-left: 10px;
    color: var(--paragraph-color);
    font-size: .95rem;
    font-weight: 600;
}

.summery-about {
    background-color: var(--sec-opacity-color);
    color: var(--paragraph-color);
    padding: 1.5rem;
    border-left: 4px solid var(--main-color);
    margin-bottom: 2rem;
}


/* ==================== Responsive About ==============*/

@media only screen and (max-width: 1399px) {
    .about-sec h1 {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 1199px) {
    .about-sec h1 {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 991px) {
    .about-sec h1 {
        font-size: 1.65rem;
    }
}

@media only screen and (max-width: 500px) {
    .about-sec .about-list li {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .about-sec h1 {
        font-size: 1rem;
    }
    .about-sec .about-desc {
        font-size: 12.5px;
    }
}