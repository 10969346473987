.checkout-container {
    padding: 1.5rem;
    border: 1.5px solid #e5eaee;
    margin-bottom: 3rem;
}

.checkout-container input,
textarea,
select {
    background-color: #fff;
    border: 2px solid #e4ecf2;
    height: 65px;
    box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
    padding-right: 40px;
    outline: none;
    cursor: pointer;
}

.checkout-container input:focus,
textarea:focus {
    border: 1px solid var(--main-color);
}

.checkout-container textarea {
    resize: none;
    padding: 15px 20px;
    min-height: 150px;
}

.checkout-container h6 {
    font-weight: 800;
    margin-block: 1rem;
}

.list-group>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: none;
    border-bottom: 1px solid #dfdede;
}

.list-group>li:nth-child(even) {
    background-color: var(--sec-bag);
}

.list-group>li:last-child h6 {
    font-weight: 800;
}