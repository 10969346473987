.totalItem {
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.totalItem h3 {
    font-weight: 600;
}

.totalItem span {
    color: var(--main-color);
    font-weight: bold;
}

.cart_parent {
    display: flex;
    flex-direction: column;
}

.cart_item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    padding: 2rem;
    border-left: 2px solid var(--main-color);
    justify-content: space-between;
    margin-bottom: 1.2rem;
}

.cart_item a {
    font-weight: 700;
    flex: 1 1;
    color: var(--paragraph-color);
}

.cart_parent .price {
    display: flex;
    flex: 1;
}

.img_cart {
    margin-right: 30px;
    flex: 1;
}

.img_cart img {
    width: 11rem;
    height: auto;
    aspect-ratio: 16/11;
    object-fit: cover;
    border-radius: 14%;
}