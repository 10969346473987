.content {
    margin-bottom: 7rem;
}

.content h1 {
    font-weight: 700;
    margin-bottom: 3rem;
    font-family: var(--heading-font);
}

.contact-form-box {
    position: relative;
    z-index: 1;
}

.contact-form-box input {
    background-color: #fff;
    border: 2px solid #e4ecf2;
    height: 65px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 20px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 0;
    padding-right: 40px;
    outline: none;
}

.contact-form-box input:focus {
    border: 1px solid var(--main-color);
}

.forget-password {
    margin-top: 1rem;
}

.forget-password a {
    color: var(--paragraph-color);
}

.forget-password a:hover {
    color: var(--main-color);
}